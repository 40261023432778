import axios ,{ AxiosRequestConfig } from "axios";

// const AUTH_TOKEN = localStorage.getItem("APUSE");
// export const NodeURL = "http://localhost:3066";
// export const SubdomainURL = "localhost:3002";

// export const NodeURL = "http://192.168.1.33:3066";
/* LIVE URL CONFIG */
export const NodeURL = "https://www.getshifts.co.uk";
export const SubdomainURL = "getshifts.co.uk";

/* STAGING URL CONFIG */

// export const SubdomainURL = "apstaging.co.uk";
// export const NodeURL = "https://www.apstaging.co.uk";
// export const SubdomainURL = "apstaging.co.uk";

export const client = axios.create({
	baseURL: NodeURL
});
// client.defaults.headers.common["Authorization"] = AUTH_TOKEN;
client.defaults.responseType = "json";
/**
 * 
 * @param {AxiosRequestConfig} options 
 * @returns 
 */
const request = options => {
	const onSuccess = response => {
		// if (response.data && response.data.status === "00") {
		//   localStorage.removeItem("APUSE");
		//   window.location = "/login";
		//   // window.alert("Session Expired");
		// }
		return response.data;
	};
	const onError = error => {
		if (error.response) {
			// Request was made but server responded with something other than 2xx
		} else {
			// Something else happened while setting up the request triggered the error
		}
		return Promise.reject(error.response || error.message);
	};
	return client(options)
		.then(onSuccess)
		.catch(onError);
};
export default request;
